/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @const environment
* @description
* Environment Variables for Demonstration
* Created by Carlos.Moreira @ 2018/05/04
*/

// Qore Components
import { AppEnvironment } from '../app/shared/models/configuration/app-environment.interface';

// Libraries Components
import { LogLevel, LogType } from 'qevo.models';

export const environment: AppEnvironment = {
	production: false,
	hmr: false,
	envName: 'demo',
	configurations:
	{
		authenticationUrl: 'https://demo.qore.cloud/Qore.IdServer/',
		consoleApiUrl: 'https://demo.qore.cloud/Qore.Api.Manager/',
		managerApiUrl: 'https://demo.qore.cloud/Qore.Api.Manager/',
		counterApiUrl: 'https://demo.qore.cloud/Qore.Api.Counter/',
		insightsApiUrl: 'https://demo.qore.cloud/Qore.Api.Insights/Insights/',
		bookingsApiUrl: 'https://qore-bookings-demo.azurewebsites.net/Qore.Bookings/',
		bookingsInsightsApiUrl: 'https://qore-bookings-demo.azurewebsites.net/Qore.Bookings.Insights/v1/',
		dispenserUrl: 'https://demo.qore.cloud/qore.dispenser/dispenser/{0}',
		displayUrl: 'https://demo.qore.cloud/qore.display/display/{0}',
		signalRUrl: 'https://demo.qore.cloud/Qore.SignalR/hubs/',
		notificationUrl: 'https://demo.qore.cloud/Qore.Notifications',
		defaultLanguage: 'pt-PT',
		availableLanguages: [
			{
				id: 2070,
				display: 'PT',
				langCode: 'pt-PT',
				name: 'Português',
				country: 'Portugal',
				isDisabled: false,
				direction: 'ltr'
			},
			{
				id: 2057,
				display: 'EN',
				langCode: 'en-GB',
				name: 'English',
				country: 'United Kingdom',
				isDisabled: false,
				direction: 'ltr'
			},
			{
				id: 1034,
				display: 'ES',
				langCode: 'es-ES',
				name: 'Spanish',
				country: 'España',
				isDisabled: false,
				direction: 'ltr'
			},
			{
				id: 14337,
				display: 'AE',
				langCode: 'ar-AE',
				name: 'Arabic',
				country: 'Dubai',
				isDisabled: true,
				direction: 'rtl'
			},
			{
				id: 2035,
				display: 'FR',
				langCode: 'fr-FR',
				name: 'Français',
				country: 'France',
				isDisabled: false,
				direction: 'ltr'
			},
		],
		logger: {
			level: LogLevel.Trace,
			type: LogType.console,
			cache: 10,
			maxCache: 20,
			time: 1,
			loggerApiUrl: 'https://localhost:5002/logger',
			separator: '#',
			logTimingRequests: false,
			intervalToClearConsole: 60
		},
		listDefaults: {
			pageSize: 15,
			sortOrder: 'asc'
		},
		mediaDefaults: {
			maxSizes: {
				images: 5 * 1024 * 1024,
				bitmaps: 1 * 1024 * 1024,
				videos: 2500 * 1024 * 1024,
				audio: 100 * 1024 * 1024
			}
		},
		security: {
			h69Ta427lUK282TXn29NAg: 0
		},
		thirdParty: {
			google: {
				mapsApiKey: 'AIzaSyAbfMujiGioBtbbE9-0ETgKFoHNCpCebkE',
				fallbackMapLatitude: 38.736946,
				fallbackMapLongitude: -9.142685
			},
			microsoft: {
				atlasSubscriptionKey: '-SBBwY5Vh4f159WXeGfqgEkXfDEDoaNs1MeeM02Qsgk',
				fallbackMapLatitude: 38.736946,
				fallbackMapLongitude: -9.142685,
				// eslint-disable-next-line max-len
				timeZoneApiUrl: 'https://atlas.microsoft.com/timezone/byCoordinates/json?subscription-key={0}&api-version=1.0&options=all&query={1},{2}',
				getTimeZoneWindowsEnumApiUrl: 'https://atlas.microsoft.com/timezone/enumWindows/json?subscription-key={0}&api-version=1.0'
			}
		},
		notification: {
			maxNumberOfRetries: 5,
			minTimeSpanLoggedToResetMaxNumberOfRetries: 1,
			timeBetweenRetries: 10,
			maxNumberOfRecoverRetries: 1,
			timeBetweenRecoverRetries: 30
		},
		versionInfo: {
			version: '6.2.12',
			date: '2025/02/12'
		}
	}
};
